import './css/About.css';
import rpmlogo from '../images/rpmlogo.png';
import { FaXTwitter } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";
import dexToolsLogo from '../images/dextools-white.png'

const About = () => {
  return (
    <div className='section' id='about'>
      <div className='about-images'>
        <img class='logo-image-about' src={rpmlogo} />
      </div>
      <div className='about-copy'>
        <p>$RedPocket</p>
        <div>
          GONG HEI FAT CHOI bitches. Double Happiness.
        </div>
        <p class="about-blurb">In Chinese culture, the tradition of exchanging red pocket money,  holds profound significance beyond its monetary value. Symbolizing blessings, familial ties, and cultural heritage. Our team wants to bring the festivities to the Solana community by airdropping communities a little <b>$REDPOCKET</b> for Chinese New Year!</p>

        <div class="social-icons">
          <a href="https://twitter.com/redpocketonsol" >
            <FaXTwitter />
          </a>
          <a href="https://www.telegram.org" >
            <FaTelegramPlane />
          </a>
        </div>
        <div>
          CA: Coming soon
        </div>
        <a class="contract-address-section" href="https://birdeye.so/?chain=solana">
          <button type="button" class="btn btn-primary btn-lg contract-address-button " disabled> Buy Now (Coming Soon)</button>
        </a>
      </div>
    </div>
  );
}

export default About;