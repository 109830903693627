import './css/Navbar.css';

const Navbar = () => {
  return (
    <div className='navbar'>
      <div className='logo'><a href="/">Red Pocket on Sol</a></div>
      <ul className='navlinks'>
        <li><a href="#about">About</a></li>
        <li><a href="#tokenomics">Tokenomics</a></li>
        <li><a href="#howToBuy">How to buy</a></li>

        <li><a href="https://birdeye.so/?chain=solana">Chart</a></li>
      </ul>
    </div>
  );
}

export default Navbar;