import './css/Tokenomics.css';

const Tokenomics = () => {
  return (
    <div className='section' id='tokenomics'>
      <div className='supply'>
        <h3 className='title'>Total Supply</h3>
        <p>1,000,000,000</p>
      </div>
      <div className='tokenomics-container'>
        <div className='item'>
          <h4>Airdrop/Marketing</h4>
          <p>10%</p>
        </div>
        <div className='item'>
          <h4>Early Contributors</h4>
          <p>5%</p>
        </div>
        <div className='item'>
          <h4>Initial Liquidity</h4>
          <p>85%</p>
        </div>
      </div>
      <div className='liq-locked'>Liquidity Locked &#128274;</div>
    </div>
  );
}

export default Tokenomics;