import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import './css/howToBuy.css';
import rpmlogo from '../images/rpmlogo.png';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  fontWeight: 'bolder',
  color: theme.palette.text.secondary,
}));

const HowToBuy = () => {
  return (
    <div class="howToBuySection" id="howToBuy">
      <img class="imageLogoHowToBuy" src={rpmlogo} />
      <div class="howToBuyContainer">
        <div class='howToBuyLabel'>
          How to buy:
        </div>


        <Box sx={{ width: '80%' }}>
          <Stack spacing={4}>
            <Item>
              1. Buy Solana from an exchange
            </Item>
            <Item>
              2. Choose and install a Solana-compatible wallet like Phantom/Solflare.
            </Item>
            <Item>
              3. Go to Birdeye and connect your wallet
            </Item>
            <Item>
              4. Swap SOL for $RedPocket
            </Item>
            <Item>
              5. Enjoy the festivities
            </Item>
          </Stack>
        </Box>
      </div>
    </div>
  );
}

export default HowToBuy;