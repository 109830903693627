import './App.css';

import Navbar from './components/Navbar.js';
import About from './components/About';
import Tokenomics from './components/Tokenomics';
import HowToBuy from './components/HowToBuy';


function App() {
  return (
    <div className="App">
      <Navbar />
      <About />
      <Tokenomics />
      <HowToBuy />
    </div>
  );
}

export default App;
